<template>
  <div class="d-flex wrap gap-code-flex">
    <div
      class="wrapper-div"
      v-for="(category, index) in crumbsList"
      :key="index"
      @click="routeTo(index)"
    >
      <div>
        <div class="d-flex g-10 align-center">
          <p class="Ktitle">{{ category[titleKey] }}</p>
          <!-- <img class="arrow" src="@/assets/icons/next-arrow50.png" /> -->
          <div class="arrow">
            <BreadCrumbNavIcon></BreadCrumbNavIcon>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="wrapper-div active">
        <div>
          <p class="active-title">{{ activeTitle }}</p>
        </div>
      </div>
      <!-- <div class="active-div"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  events: ["onTitleClick"],
  props: {
    crumbsList: {
      type: Array,
      required: true,
    },
    activeTitle: {
      type: String,
      required: true,
    },
    titleKey: {
      type: String,
      default: "title",
    },
  },
  methods: {
    routeTo(index) {
      let clickedObj = this.crumbsList[index];
      this.$emit("onTitleClick", clickedObj);
    },
    actGetDataCapitalize (words) {

      const sentance = words.split(" ");
      for (let i = 0; i < sentance.length; i++) {
        sentance[i] = sentance[i][0].toUpperCase() + sentance[i].substr(1);
      }
      return sentance.join(" ");

    }
  },
  components: {
    BreadCrumbNavIcon: () => import(/* webpackChunkName: "deleteicon" */ "../SvgImages/Breadcrumbsiconsvg.vue"),
    
     },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./BreadCrumbs.scss";
</style>
